import React from 'react'
import CalendarImg from "../images/Fall2024Calendar.pdf"
import './Calendar.css'

function Calendar() {

    return (
        <div className='calendar'>
            {/* <object data= {CalendarImg} type="application/pdf" width="100%" height="100%" className='pdf'/> */}
            <embed src = {`${CalendarImg}#page=1&zoom=100`} width="100%" height="800px"></embed>
        </div>
    )
}

export default Calendar
